import { Link as RouterLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ViewIcon from '@mui/icons-material/ReadMore';
import ReassignIcon from '@mui/icons-material/AssignmentInd';

export default function clientCard({ client, handleEdit, handleReassign, auth, locale, timeZone, sx, siteId }) {
  function handleEditClick() {
    handleEdit(client);
  }

  function handleReassignClick() {
    handleReassign(client);
  }

  const createdDate = new Date(client.createdDate);
  const displayDate = createdDate.toLocaleDateString(locale, { timeZone: timeZone });
  const displayTime = createdDate.toLocaleTimeString(locale, { timeZone: timeZone });
  const primaryGroup = auth.getUserPrimaryGroup();

  let url = `/client/${client.clientId}`;
  if (siteId) {
    url = `/site/${siteId}${url}`;
  }

  return (
    <Card sx={sx}>
      <CardContent>
        <RouterLink to={url}>
          <Typography variant="h6" color="primary">
            {`${client.firstName || ''} ${client.lastName || ''}`}
          </Typography>
        </RouterLink>
        <Typography variant="subtitle1">
          <Link href={`mailto:${client.email}`}>{client.email}</Link>
        </Typography>
        <Typography variant="subtitle1">{`Trainer: ${client.trainer?.firstName || ''} ${client.trainer?.lastName || ''}`}</Typography>
        <Typography variant="subtitle1">{`Created: ${displayDate} ${displayTime}`}</Typography>
      </CardContent>
      <CardActions>
        <RouterLink to={url}>
          <Tooltip title="View">
            <IconButton>
              <ViewIcon />
            </IconButton>
          </Tooltip>
        </RouterLink>
        <Tooltip title="Edit">
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        {(primaryGroup === 'Admin' || primaryGroup === 'SiteAdmin') && (
          <Tooltip title="Reassign">
            <IconButton onClick={handleReassignClick}>
              <ReassignIcon />
            </IconButton>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
}
