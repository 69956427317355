import { useMemo } from 'react';
import { getUsers } from '../requests.mjs';
import useResult from '../response';

export default function useGetUsers(auth, params = null) {
  const request = useMemo(
    function () {
      return getUsers(auth, params);
    },
    []
  );
  return useResult(request);
}
