import { useMemo } from 'react';
import { getClients } from '../requests.mjs';
import useResult from '../response';

export default function useGetClients(auth, params = null, siteId = null) {
  const request = useMemo(
    function () {
      return getClients(auth, params, siteId);
    },
    []
  );
  return useResult(request);
}
