import { purchasableItemLevels } from '../../common/constants.mjs';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { toTitleCase } from '../../common/utils.mjs';

export default function purchasableItemLevelSelect({ level, setLevel }) {
  function handleChange(event) {
    setLevel(event.target.value);
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="purchasable-item-level-selector-label">Purchasable Item Level</InputLabel>
      <Select
        labelId="purchasable-item-level-selector-label"
        id="purchasable-item-level-selector"
        value={level === null ? '' : level}
        label="Purchasable Item Level"
        onChange={handleChange}
      >
        {purchasableItemLevels.map((level) => {
          return (
            <MenuItem key={level} value={level}>
              {toTitleCase(level)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
