import { useState, useEffect } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Unstable_Grid2';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import WalletItemCard from '../../components/payments/wallet/item/card';
import WalletItemAdd from '../../components/payments/wallet/item/add';
import request, {
  addItemToWallet,
  removeItemFromWallet,
} from '../../apis/thrivePerformance/v1/requests.mjs';
import useGetWallet from '../../apis/thrivePerformance/v1/payments/getWallet';
import useGetPurchasableItems from '../../apis/thrivePerformance/v1/payments/getPurchasableItems';

export default function Wallet() {
  const { siteId, clientId, walletId } = useParams();
  const [setSnackbar, loginState, setLoginState, auth, userState, setUserState, user] = useOutletContext();
  const [loading, setLoading] = useState(true);
  const [walletItemCreateDialogOpen, setWalletItemCreateDialogOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [wallet, error, refreshWallet] = useGetWallet(auth, clientId, walletId, siteId);
  const [purchasableItems] = useGetPurchasableItems(auth, {
    status: 'active',
    limit: 50
  });

  function handleWalletItemCreate() {
    setWalletItemCreateDialogOpen(true);
  }

  function handleDialogClose() {
    setWalletItemCreateDialogOpen(false);
  }

  async function handleCreateWalletItem(item) {
    setProcessing(true);
    try {
      await request(addItemToWallet(auth, clientId, walletId, item, siteId));
      handleDialogClose();
      refreshWallet();
      setSnackbar('success', 'The item was added to the client wallet successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to add the item to the client wallet.');
    }
    setProcessing(false);
  }

  async function handleDeleteWalletItem(itemId) {
    setProcessing(true);
    try {
      await request(removeItemFromWallet(auth, clientId, walletId, itemId, siteId));
      refreshWallet();
      setSnackbar('success', 'The item was deleted from the client wallet successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to delete the item from the client wallet.');
    }
    setProcessing(false);
  }

  useEffect(() => {
    if (error) {
      if (loading) {
        setLoading(false);
      }
      setSnackbar('error', error.error);
    }
  }, [error]);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography variant="h3" component="h3" sx={{ mb: 2 }}>
          Wallet Items
        </Typography>
        <IconButton size="large" color="primary" onClick={handleWalletItemCreate}>
          <AddIcon />
        </IconButton>
      </Stack>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {wallet &&
          wallet.items &&
          wallet.items.map((item) => {
            return (
              <Grid xs={12} sm={4} lg={3} key={item.itemId}>
                <WalletItemCard
                  item={item}
                  processing={processing}
                  handleDelete={handleDeleteWalletItem}
                  locale={user.locale}
                  timeZone={user.zoneinfo}
                />
              </Grid>
            );
          })}
        {!wallet && loading ? (
          <Grid>
            <CircularProgress color="primary" />
          </Grid>
        ) : null}
      </Grid>
      <Dialog open={walletItemCreateDialogOpen} onClose={handleDialogClose} fullWidth>
        <DialogTitle>New Wallet Item</DialogTitle>
        <DialogContent>
          <WalletItemAdd
            items={purchasableItems?.items}
            processing={processing}
            handleCreate={handleCreateWalletItem}
            handleCancel={handleDialogClose}
            locale={user.locale}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
