import { useMemo } from 'react';
import { getPurchasableItems } from '../requests.mjs';
import useResult from '../response';

export default function useGetPurchasableItems(auth, params = null) {
  const request = useMemo(
    function () {
      return getPurchasableItems(auth, params);
    },
    []
  );
  return useResult(request);
}
