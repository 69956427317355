/* eslint-disable no-useless-escape */
const maxNameLength = 50;
const maxDescriptionLength = 255;
const maxEmailLength = 320;
const validatorCodeLength = 6;
const passwordFormat =
  /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{12,256}$/;

function isString(string) {
  if (typeof string === 'string') {
    return true;
  }
  return false;
}

function isStringOfLength(string, maxLength) {
  if (isString(string) && string.length <= maxLength) {
    return true;
  }
  return false;
}

function isName(string) {
  if (string.length === 0) return false;
  return isStringOfLength(string, maxNameLength);
}

function isDescription(string) {
  return isStringOfLength(string, maxDescriptionLength);
}

function isEmail(email) {
  if (email.length === 0) return false;
  if (isStringOfLength(email, maxEmailLength)) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
  }
  return false;
}

function isPassword(password) {
  if (password.match(passwordFormat)) {
    return true;
  }
  return false;
}

function isPasswordMatch(password1, password2) {
  if (password1 === password2) {
    return true;
  }
  return false;
}

function isValidatorCode(string) {
  if (isString(string) && string.length === validatorCodeLength) {
    if (!isNaN(string) && !isNaN(parseFloat(string))) {
      return true;
    }
  }
  return false;
}

function isBoolean(boolean) {
  if (typeof boolean === 'boolean') {
    return true;
  }
  return false;
}

function isDatetime(string) {
  try {
    const date = new Date(string);
    date.toString();
    return true;
  } catch (err) {
    return false;
  }
}

export default function validator() {
  return {
    isName,
    isDescription,
    isEmail,
    isPassword,
    isPasswordMatch,
    isValidatorCode,
    isBoolean,
    isDatetime,
  };
}
