import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { toTitleCase } from '../../../../common/utils.mjs';

export default function itemCard({ item, processing, handleDelete, locale, timeZone, sx }) {
  function handleDeleteItemClick() {
    handleDelete(item.itemId);
  }

  const createdDate = new Date(item.createdDate);
  const createdDateDisplayDate = createdDate.toLocaleDateString(locale, { timeZone: timeZone });
  const createdDateDisplayTime = createdDate.toLocaleTimeString(locale, { timeZone: timeZone });

  const startDate = new Date(item.startDate);
  const startDateDisplayDate = startDate.toLocaleDateString(locale, { timeZone: timeZone });
  const startDateDisplayTime = startDate.toLocaleTimeString(locale, { timeZone: timeZone });

  const endDate = new Date(item.endDate);
  const endDateDisplayDate = endDate.toLocaleDateString(locale, { timeZone: timeZone });
  const endDateDisplayTime = endDate.toLocaleTimeString(locale, { timeZone: timeZone });

  return (
    <Card sx={sx}>
      <CardContent>
        <Typography variant="h6" color='primary'>{item.name}</Typography>
        <Typography variant="subtitle1">{item.description}</Typography>
        <Typography variant="subtitle1">
          {item.price.toLocaleString(locale, {
            style: 'currency',
            currency: 'USD',
          })}
        </Typography>
        <Typography variant="subtitle1">
          {`Type: ${toTitleCase(item.type)}`}
        </Typography>
        <Typography variant="subtitle1">
          {`Level: ${item.level ? toTitleCase(item.level) : 'N/A'}`}
        </Typography>
        <Typography variant="subtitle1">
          {`Start Date: ${startDateDisplayDate} ${startDateDisplayTime}`}
        </Typography>
        {item.type === 'time' && (
          <Typography variant="subtitle1">
            {`End Date: ${endDateDisplayDate} ${endDateDisplayTime}`}
          </Typography>
        )}
        {item.type === 'quantity' && (
          <Typography variant="subtitle1">
            {`Remaining Sessions: ${item.quantity}`}
          </Typography>
        )}
        <Typography variant="subtitle1">
          {`Created Date: ${createdDateDisplayDate} ${createdDateDisplayTime}`}
        </Typography>
      </CardContent>
      <CardActions>
        <Tooltip title="Add Item">
          <IconButton disabled={processing || null} onClick={handleDeleteItemClick}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
